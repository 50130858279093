// Main
body {
    background: var(--main-bg);
}

.modal-open {
    overflow: hidden;
}

a {
    text-decoration: none;
}

select, input {
    background-color: var(--card-bg)
}

select option {
    background-color: white;
}

.header {
    background-color: var(--card-bg);
    width: 100%;
}

.logo {
    width: 110px;
}

.row [class^=col] {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

input::placeholder, textarea::placeholder {
    font-size: 14px;
    color: var(--secondary-text);
}

input:focus, textarea:focus, select:focus{
    outline: none !important;
}

// Text
.title-xl {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 60px;
    line-height: 73px;
}

.title-l {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
}

.title {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    // color: var(--main-text);
}

.title-2 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 24px;
    line-height: 29px;
    // color: var(--main-text);
}

.title-3 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 20px;
    line-height: 24px;
    // color: var(--main-text);
}

.title-4 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-shadow: 20px 22px 64px rgba(12, 14, 31, 0.354818);
}

.subtitle {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    // color: var(--main-text);
}

.heading {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    // color: var(--main-text);
}

.subheading {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    // color: var(--main-text);
}

.text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 17px;
    // color: var(--main-text)
}

.text-xs {
    font-family: var(--secondary-text);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
}

.special-text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
    // color: var(--secondary-text);
    text-transform: uppercase;
}

.rating-text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    // color: #44C568;
    color: var(--yellow-star);
}

.error-message {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--error);
    margin: 5px;
}

.text-white {
    color: white;
}

// Modifiers
.unpadify-x {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mr-5 {
    margin-right: 5px !important
}
.mr-10 {
    margin-right: 10px !important
}

.mr-20 {
    margin-right: 20px !important
}

.mr-30 {
    margin-right: 30px !important
}

.mr-40 {
    margin-right: 40px !important
}

.ml-5 {
    margin-left: 5px !important
}

.ml-10 {
    margin-left: 10px !important
}

.ml-15 {
    margin-left: 15px !important
}

.ml-20 {
    margin-left: 20px !important
}

.ml-50 {
    margin-left: 50px !important
}

.ml-60 {
    margin-left: 60px !important
}

.my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.my-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mx-5 {
    margin-right: 5px;
    margin-left: 5px;
}

.mx-10 {
    margin-right: 10px;
    margin-left: 10px;
}

.mx-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.mx-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.mx-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.y-scroll {
    overflow-y: scroll !important;
}

.x-scroll {
    overflow-x: scroll !important;
}

.scroll {
    overflow: scroll !important;
}

.center {
    margin: 0px auto;
    text-align: center !important;
    justify-content: center;
}

.right {
    margin-right: 0px !important;
    text-align: right !important;
    justify-content: flex-end !important;
}

.left {
    margin-left: 0px !important;
    text-align: left !important;
    justify-content: flex-start !important;
}

.is-dark {
    color: var(--main-text);
}

.is-red {
    color: red;
}

.is-light {
    color: var(--secondary-text);
}

.is-white {
    color: var(--card-bg);
}

.is-blue {
    color: var(--link);
}

.is-bold {
    font-weight: bold;
}

.is-yellow {
    color: #FFD600;
}

.is-green {
    color: var(--green-star)
}

.is-grey {
    color: rgb(158, 158, 158)
}

.blue-btn, .blue-btn i {
    background-color: var(--blue-btn);
    color: #FFFFFF;
}

.black-btn {
    background-color: var(--secondary-btn-bg);
    color: var(--secondary-btn-text)
}

.list-btn {
    background-color: var(--main-bg);
    color: var(--secondary-text);

}

.list-btn:hover, .list-btn:hover i {
    background-color: var(--main-bg);
    color: var(--link)
}

.white-btn {
    background-color: var(--secondary-btn-text);
    color: var(--secondary-text);
    border: 1px solid rgba(39, 39, 39, 0.1) !important;
}

.white-blue-btn {
    background-color: var(--secondary-btn-text);
    color: var(--link);
    border: 1px solid rgba(39, 39, 39, 0.1) !important;
}

.select-red {
    background: rgba(227, 30, 40, 0.1) !important;
}

.select-red span, .select-red i {
    color: #E31E28 !important;
}

.select-orange {
    background: rgba(246, 146, 33, 0.15) !important;
    color: #F18F1F !important;
}

.select-orange span, .select-orange i {
    color: #F18F1F !important;
}

.inline {
    display: inline;
}

.full-height {
    height: 100% !important;
}

.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.one-third-width {
    width: 33% !important;
}

.two-third-width {
    width: 66% !important;
}

.one-fourth-width {
    width: 25% !important;
}

.one-fifth-width {
    width: 20% !important;
}

.width-10 {
    width: 10% !important;
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
}

.max-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.max-screen__bottom, .remaining-width {
    flex: 1 1 auto;
}

.max-screen__bottom__2 {
    flex: 1 1 auto;
    height: 1px;
}

.cursor {
    cursor: pointer;
}

.flexer {
    display: flex;
}

.flexer-wrapper {
    flex-wrap: wrap;
}

.flexer-vcenter {
    align-items: center;
}

.flexer-spaced {
    justify-content: space-between;
}

.flexer-0 {
    flex: 0 0 1%;
}

.flexer-1 {
    flex: 0 0 8%;
}

.flexer-2 {
    flex: 0 0 19%;
}

.flexer-3 {
    flex: 0 0 25%;
}

.flexer-4 {
    flex: 0 0 32.3%;
}

.flexer-5 {
    flex: 0 0 40.6%;
}

.flexer-6 {
    flex: 0 0 49%;
}

.flexer-7 {
    flex: 0 0 57.3%;
}

.flexer-8 {
    flex: 0 0 65.6%;
}

.flexer-9 {
    flex: 0 0 75%;
}

.flexer-10 {
    flex: 0 0 79%;
}

.flexer-11 {
    flex: 0 0 90%;
}

.flexer-12 {
    flex: 0 0 98%;
}

.line-margin-0 {
    margin-bottom: 0em !important;
    margin-top: 0em !important;
}

.line-margin-xs {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.line-margin-s {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.side-margin-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.rating-text-setter {
    font-size: 24px;
    line-height: 29px;
    border-left: 1px solid lightgray;
    padding-left: 15px;
}

.breakable {
    word-break: break-word;
}

.fixed {
    position: fixed;
}

@media screen and (max-width: 1024px) {
    .flexer {
        flex-wrap: wrap;
    }

    .flexer-mobile {
        display: initial;
    }

    .center-mobile {
        margin: 0px auto !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .full-width-mobile {
        width: 100% !important;
        padding: 0px !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    .mobile-shown {
        display: block !important;
    }

    .Modal__container {
        width: 50% !important;
    }

    .Modal__full-screen-container {
        width: 100% !important;
    }

    .star-container {
        padding: 0px 2px !important;
    }

    .widget-svg {
        width: 14px !important;
        height: 14px !important;
    }

    .rating-text-setter {
        font-size: 14px;
        line-height: 14px;
    }

    .dashboard-wrapper__card:first-child {
        margin: 15px !important;
    }

    .dashboard-wrapper__card {
        margin: 15px !important;
    }

    .dashboard-wrapper__card:last-child {
        margin: 15px !important;
    }
}

.hidden {
    display: none;
}

.shown {
    display: block;
}

.borderless {
    border: none !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.on-top {
    z-index: 1;
}

.no-border {
    border: none;
}

// Elements

.input-wrapper {
    position: relative;
}

.input-wrapper__icon-right {
    position: absolute;
    top: 0;
    right: 0;
    line-height: inherit;
}

.input-wrapper__icon-left {
    position: absolute;
    top: 0;
    left: 0;
}

.input-wrapper-tags__icon-left {
    // position: absolute;
    top: 0;
    left: 0;
}

.input-field {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 35px;
}

.input-field:first-child {
    margin-top: 10px;
}

.input-field__label {
    font-size: 13px;
    color: var(--secondary-text);
}

.input-field__input {
    border: none;
    border-bottom: 1px solid var(--border);
    margin-top: 5px;
    width: 100%;
    font-size: 15px;
    color: var(--main-text);
    background: var(--card-bg);
    padding: 5px;
}

.input-field__search {
    border: none;
    background: var(--card-bg);
    width: 100%;
}

.checkbox {
    margin-right: 15px;
    margin-left: 15px;
}

.radio-btn__label {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    color: var(--main-text);
    margin: .5rem 2%;
}

.radio-btn__input {
    margin-right: 10px;
}

.icon {
    color: var(--secondary-text);
    font-size: 20px;
}

.icon-small {
    color: var(--secondary-text);
    font-size: 4px;
    line-height: 18px;
}

.link {
    color: var(--link);
    text-decoration: none;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: lighter;
    font-size: 15px;
    line-height: 21px;
}

.button {
    border-radius: 5px;
    padding: 12px;
    border: none;
    font-family: var(--main-font);
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    height: fit-content;
}

button[disabled] {
    cursor: not-allowed;
    background-color: #bbbbbb !important;
}

.search-tags {
    border-radius: 25px;
    border: 1px solid var(--border);
    margin: 0px 5px;
    padding: 4px 8px;
    background: var(--main-bg);
    box-sizing: border-box;
}

.search-tags:hover , .search-tags__active {
    color: var(--card-bg);
    background: var(--link);
}

.search-tags:hover p , .search-tags__active p {
    color: var(--link);
}

.search-input {
    border-radius: 8px;
    border: 1px solid var(--main-bg);
    padding: 0px 10px 0px 0px;
}

.search-input, .search-input input {
    background-color: var(--main-bg);
}

.search-input-tags {
    border-radius: 8px;
    border: 1px solid var(--link);
    padding: 0px 10px 0px 0px;
}

.search-input-2 {
    background: var(--card-bg);
    border: none;
    width: 100%;
    box-sizing:border-box;
}

.search-results {
    background: var(--main-bg);
    padding: 10px 40px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 1px 1px 0px 0px var(--border);
}

.search-results:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.search-results:hover {
    background-color: var(--card-bg);
}

.search-icon {
    padding: 10px;
    font-size: 15px;
}

.table {
    width: 100%;
}

.table-header th, .table-rows td {
    padding: 15px 0px;
    border-bottom: 1px solid #EBEBEB;
}

.file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

// User Registration
.regist-card {
    background: var(--card-bg);
    border-radius: 5px;
    padding: 50px;
    margin: 50px auto;
}

// Header

.profile-menu__checkbox {
    display:none;
}

.profile-menu__name {
    padding: 0px 10px;
}

.profile-menu__image {
    height: 2.5rem;
    vertical-align: middle;
    border-radius: 50%;
    background-color: var(--blue-btn);
    width: 40px;
    height: 40px;
}

.profile-menu__list {
    list-style: none;
    background-color: var(--card-bg);
    border-radius: 3px;
    position: absolute;
    right: 0;
    margin-right: 2%;
    margin-left: 2%;
    margin-top: 10px;
    width: 150px;
    padding: 0px;
    z-index: 9;
    box-shadow: -2px -2px 10px 0px var(--shadow);
}

.profile-menu__list::before {
    content: ' ';
    position: absolute;
    left: auto;
    right: 7px;
    top: -7px;
    border: 10px solid;
    border-color: var(--card-bg) transparent transparent var(--card-bg);
    box-shadow: -2px -2px 2px -1px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.profile-menu__item {
    padding: 5px 10px;
    color: var(--main-text);
}

.profile-menu__item:hover, .profile-menu__item:hover i{
    background-color: var(--main-bg);
    color: var(--link);
    border-radius: 3px;
}

.header-tabs {
    border-top: 1px solid var(--main-bg);
    box-shadow: 0px 6px 16px -15px rgba(0, 0, 0, 0.295427);
}

.text-icon {
    font-size: 15px;
    line-height: 18px;
    color: var(--secondary-text);
}

.menu-tab {
    margin: 0px 15px;
    padding: 0px 10px;
    border-bottom: 2px solid var(--card-bg);
}

.menu-tab__case {
    border-bottom: 2px solid var(--main-bg);
}

.menu-tab:hover, .menu-tab__active {
    border-bottom: 2px solid var(--blue-btn);
}

.menu-tab:hover p, .menu-tab:hover i, .menu-tab__active p, .menu-tab__active i {
    color: var(--link);
}

.menu-tab i {
    margin-right: 10px;
}

.case-btn {
    background-color: var(--blue-btn);
    display: inline-block;
    padding: 0px 30px;
    border: 2px solid var(--blue-btn);
}

.case-btn i {
    margin-right: 10px;
    color: white;
}

.case-btn:hover {
    background-color: white;
    border: 2px solid var(--blue-btn);
}

.case-btn:hover p, .case-btn:hover i {
    color: var(--link);
}

// Modal
.wcc-modal{
    z-index: 9;
}
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.Modal__container {
    background-color: var(--card-bg);
    padding: 40px;
    width: 35%;
    border-radius: 5px;
    overflow: auto;
    max-height: 80%;
}

.Modal__container-reviews {
    overflow: auto;
    width: 100%;
    height: 92%;
    max-height: 92%;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
    border-radius: 15px;
    position: fixed;
    bottom:0%;
    background-color: var(--main-bg) !important;
}

.Modal__full-screen-container {
    width: 100%;
    height: 92%;
    max-height: 92%;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
    border-radius: 15px;
    position: fixed;
    bottom:0%;
    background-color: var(--main-bg) !important;
}

.Modal__close {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
}

.Modal__steps {
    position: absolute;
    bottom: 0;
    background-color: white;
    padding: 15px;
}

// Content

.content-wrapper {
    background: var(--card-bg);
    margin: 30px auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.form-wrapper {
    background: var(--card-bg);
    margin: 0px auto 60px auto;
    padding: 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}


//// Profile

.simple-profile {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    height: 100%;
}

.simple-profile__sections {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.simple-profile__sections:last-child {
    border-bottom: none;
}

.simple_profile__upper-sections{
    margin: 20px 0px 0px 0px;
}

.icon-text {
    display: inline-flex;
    word-wrap: anywhere;
}

.simple-profile__picture {
    width: 50%;
    border-radius: 5px;
    background: var(--secondary-text);
}

.edit-profile__picture {
    width: 10%;
    border-radius: 5px;
    background: var(--secondary-text);
}

.social-icons {
    font-size: 25px;
    color: var(--blue-btn);
    margin: 5px;
    width: 36px;
}

.group-icon {
    font-size: 25px;
    background: var(--blue-btn);
    color: white;
    border-radius: 50%;
    margin: 0px 5px 0px 5px;
    text-align: center;
    padding: 10px;
}

.advanced-profile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.exp-rev-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.rev-section__tool {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5% 20%;
}

.rev-section__list {
    padding: 5% 0%
}

.bubble {
    display: inline-block;
    position: relative;
    box-shadow: 2px 1px 4px 1px var(--shadow);
    padding: 0px 15px;
    border-radius: 5px;
    width: -webkit-fill-available;
}

.bubble:before {
    content: ' ';
    position: absolute;
    left: 20px;
    right: auto;
    bottom: -7px;
    border: 7px solid;
    border-color: transparent var(--card-bg) var(--card-bg) transparent;
    box-shadow: 2px 2px 2px 0px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.reviewer-wrapper {
    margin: 20px 0px;
}

.reviewer-wrapper__info {
    margin: 0px 15px;
}

.rating-box {
    background-color: var(--main-bg);
    border-radius: 40px;
    padding: 5px 0px;
}

.warning-message {
    background-color: var(--warning);
    padding: 15px;
}

// User Resources

.dashboard-section {
    padding: 20px 30px;
}

// User Organizations view

.dashboard-user-section {
    padding: 20px 0px;
    min-height: 400px;
}

// Toggle

.toggle-wrapper {
    margin: 10px 0px;
}

.toggle {
    margin: 2px 0px;
}

.toggle-switch {
    position: relative;
    width: 65px;
    margin: 0px auto;
    &-checkbox {
      display: none;
    }
    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid #bbb;
      border-radius: 20px;
      margin: 0;
    }
    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      font-family: var(--main-font);
      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 25px;
        padding: 0;
        line-height: 25px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: "On";
        text-transform: uppercase;
        padding-left: 10px;
        background-color: var(--orange-btn);
        color: #fff;
      }
    }
    &-disabled {
      background-color: #ddd;
      cursor: not-allowed;
      &:before {
        background-color: #ddd;
        cursor: not-allowed;
      }
    }
    &-inner:after {
      content: "Off";
      text-transform: uppercase;
      padding-right: 10px;
      background-color: #bbb;
      color: #fff;
      text-align: right;
    }
    &-switch {
      display: block;
      width: 17px;
      height: 17px;
      margin: 4px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      border: 0 solid #bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
      .toggle-switch-inner {
        margin-left: 0;
      }
      .toggle-switch-switch {
        right: 0px;
      }
    }
  }

// Dashboard

.dashboard-wrapper {
    margin: 30px auto;
}

.dashboard-wrapper__card {
    background-color: var(--card-bg);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    margin: 10px 0px;
}

.dashboard-wrapper__card:first-child {
    margin: 10px 0px 10px 0px;
}

.dashboard-wrapper__card:last-child {
    margin: 10px 0px 10px 0px;
}

.dashboard-wrapper__content {
    padding: 20px 30px;
    box-sizing: border-box;
}

// Case generation

.step {
    border: 1px solid var(--step-menu);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    color: var(--step-menu);
    font-size: 24px;
    padding: 5px;
}

.step-active {
    background: var(--link);
    border: 1px solid var(--link);
    color: var(--link);
}

.step-past {
    background: var(--link);
    border: 1px solid var(--link);
    color: var(--card-bg);
}

.line {
    border-bottom: 2px dotted var(--step-menu);
    width: 83%;
}

.form-icon {
    margin-right: 30px;
    color: var(--secondary-text);
    font-size: 20px;
    margin-top: 10px;
}

div[class^="ReactFlagsSelect"] button {
    border: none;
    padding: 5px 0px;
}

div[class^="ReactFlagsSelect"] button[aria-expanded="true"]:after {
    border-bottom: 5px solid var(--main-text);
}

div[class^="ReactFlagsSelect"] button[aria-expanded="false"]:after {
    border-top: 5px solid var(--main-text);
}

div[class^="ReactFlagsSelect"] button span {
    color: var(--main-text);
}

.chip, .chip i {
    background: var(--link) !important;
    color: white !important;
    margin-bottom: 0px;
}

// Case page

.case-sides {
    background: var(--card-bg);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    margin-top: 1px;
}

.case-links {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.select-special {
    border-radius: 5px;
    margin-top: 0px;
    border-bottom: none;
}

.upload-wrapper {
    border-radius: 3px;
    border: 1px dashed lightgray;
    padding: 10px;
    width: fit-content;
}

.upload-wrapper__icon {
    background: var(--link);
    padding: 6px;
    color: white;
    border-radius: 5px;
}

.comment-bubble {
    background: var(--card-bg);
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
}

.comment-bubble input {
    border: none;
    padding: 20px;
    border-radius: 60px;
}

// Chats

.chat-wrapper {
    background-color: var(--card-bg);
    border-radius: 8px;
    margin: 30px auto !important;
}

.chats-input {
    margin-top: 0px !important;
}

.chats-input input, .chats-input textarea {
    border-bottom: none !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 100%;
}

.chats-multi .search-wrapper {
    border: none !important;
    padding: 0px !important;
}

.chats-multi .chip {
    margin-bottom: 0px !important;
}

.optionContainer {
    font-size: 15px;
    background-color: var(--card-bg);
}

.optionContainer span {
    color: var(--secondary-text);
}

.chats-max-screen {
    display: flex;
    flex-direction: column;
}

.chats__date-bubble {
    border-radius: 13px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
    padding: 5px 20px;
    width: fit-content;
}

.chats__broadcast {
    background: rgba(246, 146, 33, 0.1);
    padding: 20px;
}

.chats__inactive {
    border-left: 4px solid var(--card-bg);
}

.chats__active, .chats__item:hover {
    background: var(--main-bg);
    border-left: 4px solid var(--orange-btn);
}

.chats__unread {
    background: var(--orange-btn);
    padding: 0px 8px;
    border-radius: 8px;
    color: var(--secondary-btn-text);
    font-size: 10px;
    font-weight: bolder;
}

.start-thread {
    display: none;
}

.thread-wrapper:hover .start-thread {
    display: block !important;
}

.parent-thread {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
}


// Lists
.create-list-btn {
    width: 90%;
    background: #0065FD;
    border-radius: 5px;
    padding: 1px;
}

.create-list-btn i {
    margin-right: 10px;
    color: white;
}

.create-list-btn:hover {
    background-color: white;
    border: 2px solid var(--blue-btn);
}

.create-list-btn:hover p, .create-list-btn:hover i {
    color: var(--link);
}

// filters
.list-menu {
    width: 98%;
    height: 40px;
    border-radius: 6px;
    padding: 4px;
    margin-bottom: 10px;
}

.list-menu i {
    margin-right: 10px;
    color: var(--secondary-text);
}

.list-menu:hover , .list-menu__active {
    background: var(--main-bg);
    color: var(--link);
}
.list-menu:hover p , .list-menu:hover i, .list-menu__active p, .list-menu__active i {
    color: var(--link);
}



.top-list__sections {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.list-detail-card {
    background: var(--card-bg);
    border-radius: 5px;
    padding: 50px;
    // margin: 50px auto;
}

.list-card {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

// Tool
// Tools list
.wcc-tools{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    .wcc-tool{
        flex: 1 0 32.4%;
        max-width: 20%;
        margin-bottom: 30px;
    }

    .tool-name {
        width: auto;

    }
}

.tool-icons {
    width: 43px;
    height: 43px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}


.tool-text-setter {
    font-size: 14px;
    line-height: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 10px;
}

.tool-icons img {
    width: 27px;
    height: 27px;
}

.tool-icons__inactive img {
    width: 27px;
    height: 27px;
    filter: grayscale(100%) brightness(100%) contrast(50%);
    // -webkit-filter:grayscale(100%);
}

.tool-detail__wrapper{
    padding:5% 20%;
}

.tool-detail__container{
    background: var(--card-bg);
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    padding: 25px 0px;
}

.detail__picturec_content{
    margin-left: 30px;
    padding: 5px;
    background: var(--card-bg);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-width: 130px;
    min-height: 115px;
    display: flex;
    align-items: center;

    .tool-detail__picture {
        max-width: 100%;
    }
}

.tool-noFlag {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--error);
    margin: 5px;
    padding: 3px 6px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    vertical-align: middle;
}

.tool-delete {
    font-size: 12px;
    line-height: 28px;
    margin-right: 40px;
}

.tool-delete i {
    opacity: 0;
}

.tool-delete:hover i {
    opacity: 1;
    color: var(--secondary-text);
    transition: 200ms;
}


// Three-dots
.three-dots {
    background-color: var(--main-bg);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: var(--secondary-text);
}

.three-dots:hover  {
    color: var(--link);
    border: 1px solid white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.three-dots-menu__item {
    padding: 5px 10px;
    color: var(--secondary-text);
    text-align: left;
}

.three-dots-menu__item:hover, .three-dots-menu__item:hover i{
    background-color: var(--main-bg);
    color: var(--link);
    border-radius: 3px;
}

.three-dots-menu__list {
    list-style: none;
    background-color: var(--card-bg);
    border-radius: 3px;
    position: absolute;
    right: 0;
    margin-right: 25%;
    margin-left: 2%;
    margin-top: 10px;
    width: 200px;
    padding: 0px;
    z-index: 9;
    box-shadow: -2px -2px 10px 0px var(--shadow);
}

.three-dots-menu__list::before {
    content: ' ';
    position: absolute;
    left: auto;
    right: 7px;
    top: -7px;
    border: 10px solid;
    border-color: var(--card-bg) transparent transparent var(--card-bg);
    box-shadow: -2px -2px 2px -1px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.MuiButtonBase-root-MuiIconButton-root {
    border: 1px solid var(--border) !important;
    border-radius: 5px !important;
    background: var(--card-bg )!important;
}

.MuiButtonBase-root-MuiIconButton-root path {
    color: var(--main-text) !important;
}

.MuiButtonBase-root-MuiIconButton-root:hover, .MuiButtonBase-root-MuiIconButton-root:hover path {
    color: var(--link) !important;
    background: var(--main-bg)!important;
}

.MuiList-root-MuiMenu-list ul {
    background: var(--card-bg) !important;
    border: 1px solid var(--border) !important;
    border-radius: 0px !important;
}

.MenuItem:hover, .MenuItem:hover i {
    color: var(--link);
    background: var(--main-bg)!important;
}

// Tag
.tag {
    border-radius:25px;
    background-color: var(--main-bg);
    margin-right: 20px;
    text-align: center;
    padding: 5px 1px;
}

.tag:hover {
    color: var(--link);
    border: 1px solid white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.tag-menu__list {
    list-style: none;
    background-color: var(--card-bg);
    border-radius: 3px;
    position: absolute;
    right: 0;
    margin-right: 19.5%;
    margin-left: 2%;
    margin-top: 10px;
    width: 250px;
    padding: 0px;
    z-index: 9;
    box-shadow: -2px -2px 10px 0px var(--shadow);
}

.tag-menu__list::before {
    content: ' ';
    position: absolute;
    left: auto;
    right: 7px;
    top: -7px;
    border: 10px solid;
    border-color: var(--card-bg) transparent transparent var(--card-bg);
    box-shadow: -2px -2px 2px -1px var(--shadow);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

// tootips

    /* Wrapping */
    .tooltip-wrapper {
        display: inline-block;
        position: relative;
    }

        /* Absolute positioning */
    .tooltip-tip {
        position: absolute;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        color: var(--secondary-text);
        background: var(--main-bg);
        font-size: 14px;
        font-family: var(--main-font);
        box-shadow: 0px 4px 10px var(--shadow);
        line-height: 1;
        z-index: 100;
        white-space: nowrap;
    }

    .tooltip-tip.noBg {
        background: var(--card-bg);
    }

        /* Absolute positioning */
    .tooltip-tip.top {
        top: calc(30px * -1);
    }

        /* Absolute positioning */
    .tooltip-tip.right {
        left: calc(100% + 20px);
        top: 50%;
        transform: translateX(0) translateY(-50%);
    }

        /* Absolute positioning */
    .tooltip-tip.bottom {
        bottom: calc(20px * -1);
    }
    .tooltip-tip.bottom-x {
        bottom: calc(120px * -1);
        left: 95%;
    }
        /* Absolute positioning */
    .tooltip-tip.left {
        left: auto;
        right: calc(100% + 20px);
        top: 50%;
        transform: translateX(0) translateY(-50%);
    }

    // rating card
    .rating-wrapper {
        background: var(--card-bg);
        margin: 20px auto;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 2% 2%;
    }

    .rating-bar-5 {
        background-color: #76CAA0 ;
        height: 21px;
    }

    .rating-bar-4 {
        background-color: #ADD884 ;
        height: 21px;
    }


    .rating-bar-3 {
        background-color: #FFD93B ;
        height: 21px;
    }

    .rating-bar-2 {
        background-color: #FFB33A ;
        height: 21px;
    }

    .rating-bar-1 {
        background-color: #FF8D57 ;
        height: 21px;
    }

    // wizard
    .wizard-card {
        padding: 1% 2%;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
        border: solid 0.5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .wizard-section {
        padding:2% 20%;
    }
    .wizzard-resume-line {
        border-bottom: solid 0.5px var(--border);
    }

    // add tool
    .add-tool {
        background-color: var(--main-bg);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: var(--secondary-text);
    }

    .add-tool:hover  {
        color: var(--link);
        border: 1px solid white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    }

    .add-tool-menu__item {
        padding: 5px 10px;
        color: var(--secondary-text);
        text-align: left;
    }

    .add-tool-menu__item:hover, .add-tool-menu__item:hover i{
        background-color: var(--main-bg);
        color: var(--link);
        border-radius: 3px;
    }

    .add-tool-menu__list {
        list-style: none;
        background-color: var(--card-bg);
        border-radius: 3px;
        position: absolute;
        right: 0;
        margin-right: 20%;
        margin-left: 2%;
        margin-top: 10px;
        width: 200px;
        padding: 0px;
        z-index: 9;
        box-shadow: -2px -2px 10px 0px var(--shadow);
    }

    .add-tool-menu__list::before {
        content: ' ';
        position: absolute;
        left: 27px;
        right: auto;
        top: -7px;
        border: 10px solid;
        border-color: var(--card-bg) transparent transparent var(--card-bg);
        box-shadow: -2px -2px 2px -1px var(--shadow);
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    // Pagination
.wcc-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    path,
    button {
        color: var(--main-text);
    }
    .Mui-selected {
        background-color: var(--link) !important;
        color: #fff !important;
    }
}
.profileImage {
    border-radius: 10%;
    background-color: #0065FD;
    color: #fff;
    width: 6rem;
    height: 6rem;
    line-height: 6rem; 
    font-size: 3rem;
    margin-left: 5rem;
 }
 .EditProfileImage {
     border-radius: 10%;
     background-color: #0065FD;
     text-align: center;
     color: #fff;
     width: 2.6rem;
     height: 2.6rem;
     line-height: 2.5rem; 
     font-size: 1.3rem;
  
  } 
  .MenuProfileImage {
     border-radius: 50%;
     background-color: #0065FD;
     text-align: center;
     color: #fff;
     width: 2.5rem;
     height: 2.5rem;
     line-height: 2.5rem; 
     font-size: 1rem;
  
  }
 
 




